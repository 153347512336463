import { WebStorageStateStore } from 'oidc-client'
import AuthService from './authService'
import appSettings from '@/appSettings'

const STS_DOMAIN = appSettings.getIdentityURL()
//  process.env.VUE_APP_IDENTITY_DOMAIN
const settings = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: STS_DOMAIN,
    client_id: appSettings.getClientName(),
    redirect_uri: `${appSettings.getFrontURL()}callback`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${appSettings.getFrontURL()}silent-renew`,
    response_type: 'code',
    scope: 'openid profile offline_access',
    post_logout_redirect_uri: appSettings.getFrontURL(),
    filterProtocolClaims: true,
    clockSkew: 7200000
};
const authService = new AuthService(settings)
export default authService